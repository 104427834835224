import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { InputStyle } from '../../shared/form-control/Input';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { MembersFilterValue } from '../../../models/TableViewFilters';
import { FilterProps } from './FilterProps';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { Roles } from '../../../models/Role';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import { useRecoilValue } from 'recoil';
import { Option } from '../../Option';
import Checkbox from '../../shared/form-control/Checkbox';
import UserListRenderer from '../../ownership/UserListRenderer';
import { PeopleType } from '../../../models/Distribution';
import { ImageSize } from '../../ownership/ProfileImageStack';
import SkeletonLoader from '../../shared/skeleton-loader/SkeletonLoader';
import useFetchClientUsers from '../../../hooks/useFetchClientUsers';
import { SearchInput } from '../../shared/form-control/SearchInput';

const MembersFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const currentUser = useRecoilValue(currentUserAtom);
  const hasPermission = usePermissions();
  const { filters, setFilters } = useTableViewFilters();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<Option<string, boolean>[]>([]);

  const filterValue = useMemo(
    () => filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as MembersFilterValue | undefined,
    [columnConfig.value, filters, selectedTemplateId],
  );

  const { data: clientUsers = [], isFetching, isLoading } = useFetchClientUsers();

  const sortedUsers = useMemo(() => {
    return clientUsers
      .slice() // Create a shallow copy before sorting to avoid mutating the original array
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .map((user) => ({
        id: `${user.id}`,
        text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
        value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.id,
      }));
  }, [clientUsers, hasPermission, currentUser?.id]);

  useEffect(() => {
    setFilteredUsers(sortedUsers);
  }, [sortedUsers]);

  const handleSearch = useCallback(
    (term: string) => {
      setFilteredUsers(
        clientUsers
          .filter(({ firstName = '', lastName = '', email = '' }) => `${firstName} ${lastName} ${email}`.toLowerCase().includes(term.toLowerCase()))
          .map((user) => ({
            id: `${user.id}`,
            text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
            value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.id,
          })),
      );
    },
    [clientUsers, hasPermission, currentUser?.id],
  );

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchPhrase = event.target.value;
    setSearchTerm(searchPhrase);
    handleSearch(searchPhrase);
  };

  const updateFilter = (selectedMember: Option<string, boolean>) => {
    const updatedFilter = filterValue?.includes(selectedMember.id)
      ? filterValue.filter((id) => id !== selectedMember.id)
      : [...(filterValue || []), selectedMember.id];

    setFilters(columnConfig, selectedTemplateId, updatedFilter.length ? updatedFilter : undefined);
  };

  const isSelected = (optionId: string) => filterValue?.includes(optionId) ?? false;

  return (
    <div>
      <SkeletonLoader ready={!isLoading && !isFetching} type="blockRow" rows={6}>
        <div className="-mx-2 my-2">
          <SearchInput style={InputStyle.MINIMAL} value={searchTerm} onChange={onSearchTextChange} />
        </div>
        <div className="-mr-4 h-64 w-72 space-y-4 overflow-y-auto">
          {filteredUsers.map((option) => (
            <div key={option.id} className="flex items-center gap-2">
              <Checkbox value={isSelected(option.id)} onChange={() => updateFilter(option)} role="menuitem" />
              <UserListRenderer id={option.id} text={option.text} value={PeopleType.Member} size={ImageSize.XS} />
            </div>
          ))}
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default MembersFilter;
